ion-content {
    --color: white !important;
    overflow: hidden;
    max-height: 100%;
}

.background {
    height: 100%;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    background: url("../assets/iOS/bg_paper.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

.words {
    height: 100%;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    background: url("../assets/iOS/bg_words.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

.overlay {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url("../assets/iOS/bg_overlay.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

.info-wrapper, .info-overlay {
    position: fixed;
    width: 100%;
    height: calc(100% - 50px);
    top: 50px;
    left: 0;
    transition: opacity 500ms;
}

.info-wrapper {
    background: rgba(0,0,0,0.3);
    flex-direction: column;
    align-content: center;
    z-index: 30000 !important;
}

.info-wrapper .info-overlay {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 30005 !important;
}

.info-overlay .vfill {
    height: 55px;
}

.info-overlay .top-bar-info, .info-overlay .bottom-bar-info {
    font-size: 12pt;
    font-family: 'Barlow Black';
    color: white;
    display: flex;
    flex-direction: row;
    text-align: left;
    margin: 0 10px 0 10px;
}
#question-number {
    text-align: right;
}

.info-overlay .info-message {
    height: fit-content;
}

.info-overlay .top-bar-info {
    justify-content: space-between;
}

.info-overlay .bottom-bar-info {
    align-items: flex-end;
}

.bottom-bar-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bottom-bar-info .home-label {
    padding-left: .5em;
    padding-right: 2.5em;
}

.bottom-bar-info .info-label {
    padding-left: .5em;
    padding-right: 1em;
}

.bottom-bar-info .scores-label {
    padding-left: 1em;
    padding-right: 1em;
}

.bottom-bar-info .settings-label {
    padding-left: 0en;
}

.bottom-bar-info div:last-child {
    text-align: right;
}

.info-wrapper .info-objects {
    pointer-events: none;
    justify-content: space-between;
}

.select-interface .alert-wrapper {
    --background: #221e16;
}

.select-interface .alert-wrapper button[aria-checked="true"] .alert-radio-icon {
    border-color: #faad43;
}

.select-interface .alert-wrapper .alert-radio-label {
    color: #ffffff;
}

.md .select-interface .alert-wrapper button[aria-checked="true"] .alert-radio-inner {
    background: #faad43;
}

.ios .select-interface .alert-wrapper button[aria-checked="true"] .alert-radio-inner {
    border-color: #faad43;
}

.select-interface .alert-wrapper button[aria-checked="true"] .alert-radio-label {
    color: #faad43;
}

.select-interface .alert-wrapper .alert-button span {
    color: #faad43;
}

ion-segment-button, ion-reorder-group ion-item, ion-reorder {
    --color: #ffffff;
    --background-checked: #faad43;
    --color-checked: #ffffff;
    --border-color: #faad43;
    --border-style: solid;
    --border-radius: 6px;
    --border-width: 2px;
    --indicator-color: none;
    
    --min-height: 3em;
    --max-height: 3em;
    --transition: width 0s;
}

ion-segment-button.segment-button-checked {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, .5) !important;
}

ion-button {
    --background: #faad43;
    --background-activated: #a86b18;
    font-size: 24pt;
    height: 54px;
    margin: none;
}

.ui-wrapper ion-button {
    --background: #51891a;
}

ion-button[disabled] {
    --background: #a86b18;
    --opacity: 1;
}

.modal-wrapper, .info-objects {
    height: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center; 
    align-content: center;
}

ion-modal {
    --ion-background-color: rgba(0,0,0,.9);
}

.ios #warning .modal-wrapper, #scores .modal-wrapper {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
}

.ios .info-wrapper .info-overlay {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
}

#info .modal-wrapper, #warning .modal-wrapper {
    justify-content: space-between;
}

#settings .modal-wrapper {
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.9);
}

#settings .modal-wrapper .bottombar-wrapper{
    margin-bottom: 10px;
}

#settings p {
    font-family: 'BarlowCondensed Thin';
}

.settings-list-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30%;
    max-height: fit-content;
    background: #faad43;
}

.settings-list {
    height: 75%;
    width: 90%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    align-self: center;
    justify-content: space-evenly;
    background: #faad43;
}

.settings-list .difficulty-settings-wrapper {
    display: flex;
    flex-direction: column;
}

.settings-list .difficulty-settings-invisible {
    display: none;
}

.settings-list .difficulty-settings-visible {
    display: initial;
}

.settings-list ion-segment {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
}

.settings-list ion-segment ion-segment-button {
    --background-checked: #d89130;
    --border-color: #d89130;
    min-height: 0;
    min-width: 0;
    max-height: none;
    margin: 3px;
}

.settings-list ion-segment-button ion-label {
    font-size: 16pt !important;
    line-height: 22pt !important;
    margin: 0;
}

.settings-list ion-segment-button.segment-button-checked {
    box-shadow: 2px 2px 5px rgba(168, 107, 24, .8) !important;
}

.settings-list ion-item {
    max-height: 2.5em;
    line-height: 2.5em;
    align-items: center;
    width: 100%;
    padding: 1px 0 1px 0;
    --border-color: #d89130;
    --min-height: 0px;
}

.settings-list .toggle-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-self: center;
}

.ios ion-list .toggle-wrapper{
    margin-bottom: 10px;
}

.settings-list ion-label {
    --color: white !important;
    color: white !important;
    align-self: center;
    font-family: "BarlowSemiCondensed Regular";
    font-size: 14pt !important;
    font-weight: 600;
    margin: 0;
}

.toggle-wrapper ion-label {
    width: fit-content;
}

.settings-list ion-toggle {
    margin-left: 1em;
    --track-background: #fbc376;
    --track-background-checked: #f0f0f0;
    --handle-background: #a86b18;
    --handle-background-checked: #faad43;
}

.settings-list a {
    color: inherit;
    text-decoration: inherit;
}

.copyright {
    max-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 14%;
    color: #e59c38;
    background: #a86b18;
    font-size: 11px;
}

.copyright span {
    display: inline-block;
    margin-bottom: 1px;
}

.modal-wrapper p {
    display: inline-block;
    width: 75%;
    align-self: center;
}

#marketing_text_wrapper p {
    text-align: left;
    display: inline-block;
    width: 75%;
    align-self: center;
}

#privacy_policy_link {
    text-decoration: underline!important;
    color: #faad44!important;
}

.modal-fullscreen {
    --width: 100%;
    --height: 100%;
}

.ios #scores .top-bar {
    padding-top: 3px !important;
}

.home-logo-overlay {
    margin-top: 9em;
}

#info .modal-logo, #settings .modal-logo {
    width: 70%;
    height: 90px;
}

.marketing-text-wrapper {
    max-height: 40vh;
    padding: 0 5px 00 5px;
    overflow-y: auto !important;
    font-family: 'Barlow Regular';
    color: white;
    font-size: 16pt;
  }

#info a {
    color: white;
    text-decoration: none;
}

#settings p {
    color: white;
    font-size: 16pt;
}

.settings-list ion-item {
    max-height: 3em;
    line-height: 2.5em;
}

#warning .message-block {
    color: white;
    font-size: 20pt;
    margin-top: 25vh;
    align-self: center;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#warning .warning-message {
    margin-bottom: 20px;
}

#warning ion-button {
    font-size: 22pt;
    height: 50px;
    width: 75%;
    margin-bottom: 20px;
    align-self: center;
    min-height: unset;
}

#warning ion-button.yes {
    box-shadow: 5px 10px 5px rgba(0, 0, 0, .5) !important;
}

#warning ion-button.no {
    --background: transparent;
    --border-color: #faad43;
    --border-style: solid;
    --border-width: 2px;
}

#warning ion-button.yes.ion-activated {
    --border-color: #a86b18 !important;
}

.bottom-bar-animation-wrapper {
    opacity: 0;
}

/* creates and animates a cheveron to indicate text is scrollable*/
.marketing-scroll-indicator {
    position: relative;
    top: -0px;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
} 
  
.marketing-scroll-indicator {
animation: rotate_pulse 7s .1s 1 ease-out forwards;
opacity: 0;
}

@keyframes rotate_pulse {
0% {
    opacity: 0;
    transform: rotate(0deg);
}
10% {
    opacity: .1;
    transform: rotate(-45deg);
}
33% {
    opacity: .6;
    transform: rotate(-45deg);
}
66% {
    opacity: .1;
    transform: rotate(-45deg);
}
100% {
    opacity: .6;
    transform: rotate(-45deg);
}
}

@media screen and (min-height: 600px) {
    .info-wrapper, .info-overlay {
        height: calc(100% - 80px);
        top: 80px;
    }
}

@media screen and (min-height: 700px) {
    .info-wrapper, .info-overlay {
        height: calc(100% - 80px);
        top: 80px;
    }

    ion-button {
        height: 60px;
        font-size: 28pt;
    }

    .info-overlay .vfill {
        height: 60px;
    }

    .settings-list {
        margin: 25px 0 25px 0;
    }

    .settings-list ion-item {
        max-height: 4em;
        line-height: 2.5em;
    }
    .settings-list ion-label {
        font-size: 18pt !important;
    }

    .modal-fullscreen {
        --width: 100%;
        --height: 100%;
    }
}

@media screen and (min-height: 800px) {
    .info-wrapper, .info-overlay {
        height: calc(100% - 90px);
        top: 90px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 768px) {
    ion-modal {
        --ion-background-color: rgba(0,0,0,.9);
    }

    ion-button {
        height: 100px;
        font-size: 36pt;
    }

    #info .modal-wrapper {
        justify-content: center;
    }

    #settings p {
        font-size: 20pt;
        font-weight: 600;
        width: 90%;
    }

    .scores-wrapper .instruction-label, .scores-wrapper .scores-explanation-label {
        font-size: 25px;
    }

    .explanation-text {
        font-size: 20px;
    }

    #warning .message-block {
        margin-top: 20%;
        height: 60%;
    }
    
    #warning .warning-message {
        font-size: 24pt;
    }

    .info-overlay .vfill {
        height: 75px;
    }

    .marketing-text-wrapper {
        font-size: 19pt;
    }
}

@media screen and (min-height: 1300px) and (min-width: 1000px) {
    .info-overlay .vfill {
        height: 85px;
    }

    .info-overlay .top-bar-info, .info-overlay .bottom-bar-info {
        font-size: 16pt;
    }

    .modal-fullscreen {
        --width: 100%;
        --height: 100%;
    }

    .modal-fullscreen ion-backdrop {
        --backdrop-opacity: 0.7;
    }
} 

/* show notice if screen too small */
.size-alert {
    display: none;
}

.size-alert .alert-wrapper {
    max-width: 50vw;
    max-height: 50vh;
}

.size-alert .alert-wrapper .alert-message{
    font-size: 27px;
}

@media screen and (max-width: 280px), (max-height: 600px) {
    .size-alert {
      display: flex;
    }
}

@media screen and (max-width: 220px) {
    .size-alert .alert-wrapper {
        min-width: 100vw;
        max-height: 80vh;
        height: 80vh;
    }

    .size-alert .alert-wrapper .alert-message {
        max-height: 80vh;
    }
}

@media screen and (max-width: 150px) {
    .size-alert .alert-wrapper .alert-message {
        font-size: 20px;
    }
}

@media screen and (max-width: 100px) {
    .size-alert .alert-wrapper .alert-message {
        font-size: 10px;
    }
}

@media screen and (max-height: 550px) {
    .size-alert .alert-wrapper {
        max-width: 80vw;
        max-height: 80vh;
    }
}

@media screen and (max-height: 220px) {
    .size-alert .alert-wrapper .alert-message {
        font-size: 20px;
    }
}

@media screen and (max-height: 150px) {
    .size-alert .alert-wrapper .alert-message {
        font-size: 10px;
    }
}

#info a {
    color: #faad44!important;
    text-decoration: underline!important;
  }
  