.ui-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.category-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.difficulty-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.ad-buffer {
  padding-top: 50px;
}

.ui-container ion-segment div {
  display: flex;
  justify-content: left;
  width: 40%;
}

.ui-container .button-wrapper {
  width: 100%;
  text-align: center;
}

.ui-container ion-button {
  width: 75%;
  font-size: 27pt;
}

.ui-container ion-icon {
  padding-right: 0.5em;
}

.ui-container ion-label {
  color: white;
  width: 100%;
  text-align: center;
  margin-bottom: .2em;
  font-size: 14pt;
}

.categories .segment-button-checked {
  background-color: #faad43;
}

.categories ion-segment-button ion-label {
  font-size: 12pt;
}

.difficulty ion-segment-button ion-label {
  font-size: 12pt;
  align-self: center;
  margin: 0px;
}

.difficulty ion-segment-button {
  min-height: 33px;
}

.categories ion-segment-button {
  min-height: 30px;
}

.categories ion-label {
  text-align: left;
  margin-bottom: 14px;
}

.ui-label {
  font-family: 'Barlow Regular';
}

.ui-container ion-segment.categories {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-self: center;
  justify-content: space-evenly;
}

.ui-container ion-segment div {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.ui-container ion-segment-button {
  width: 90%;
  align-self: center;
  margin: 5px;
}

.ui-container ion-segment.difficulty {
  padding: 0 10px 10px 10px;
  z-index: 0;
}

.start-button {
  margin-bottom: 20px;
  min-height: unset;
}

.difficulty .segment-button-checked {
  background-color: #faad43;
}

.difficulty ion-segment-button {
  background-color: #a86b18;
  border-color: none;
  border-style: solid;
  border-width: 0px;

  box-shadow: 5px 10px 5px rgba(0, 0, 0, .5);
}

ion-segment-button.dummy {
  visibility: hidden;
  pointer-events: none;
}

@media screen and (min-height: 600px) {
  .difficulty ion-segment-button ion-label, .categories ion-segment-button ion-label {
    font-size: 13pt;
  }

  .logo {
    margin-top: 50px;
  }

  .ad-buffer {
    padding-top: 50px;
  }

  .difficulty ion-segment-button {
    min-height: 38px;
  }

  .ui-container ion-label {
    font-size: 16pt;
  }
}

@media screen and (min-height: 700px) {
  .difficulty ion-segment-button ion-label, .categories ion-segment-button ion-label {
    font-size: 14pt;
  }

  .logo {
    margin-top: 50px;
  }

  .ad-buffer {
    padding-top: 58px;
  }

  .difficulty ion-segment-button {
    min-height: 38px;
  }

  .categories ion-segment-button {
    max-height: 40px;
  }
}

@media screen and (min-height: 800px) {
  .difficulty ion-segment-button ion-label, .categories ion-segment-button ion-label {
    font-size: 15pt;
  }

  .ad-buffer {
    padding-top: 60px;
  }

  .difficulty ion-segment-button {
    min-height: 48px;
  }

  .categories ion-segment-button {
    max-height: 52px;
  }

  .ui-container ion-label {
    font-size: 17pt;
  }

  .ui-container ion-button {
    height: 78px;
    font-size: 29pt;
  }
}

@media screen and (min-height: 1000px) and (min-width: 700px) {
  .difficulty ion-segment-button ion-label, .categories ion-segment-button ion-label {
    font-size: 18pt;
  }

  .ad-buffer {
    padding-top: 85px;
  }

  .ui-container ion-button {
    font-size: 32pt;
  }

  .ui-container ion-label {
    font-size: 20pt;
  }

  .categories ion-segment-button {
    min-height: 50px;
  }

  .categories ion-segment-button {
    max-height: 54px;
  }

  .ui-container ion-button {
    height: 100px;
    font-size: 40pt;
  }

  .start-button {
    margin-bottom: 45px;
  }
}

@media screen and (min-height: 1100px) and (min-width: 900px) {
  .logo {
    height: 100px;
  }

  .ui-container ion-label {
    font-size: 24pt;
  }

  .ui-container ion-segment-button {
    height: 5em;
  }

  .ui-container ion-segment-button ion-label {
    font-size: 22pt;
  }

  .ui-container ion-button {
    height: 115px;
    font-size: 55pt;
  }

  .start-button {
    margin-bottom: 100px;
  }

  .categories ion-segment-button {
    max-height: 70px;
  }
}