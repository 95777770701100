.review-wrapper {
  height: 100%;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.review-wrapper.review-hidden-ui {
  display: none;
}

.review-wrapper.review-hidden-ui + .bottom-bar-animation-wrapper {
  display: none;
}

.review-wrapper .header {
  width: 100%;
  height: 25px;
  margin: 10px;
}

.correct, .incorrect {
  max-height: 45px;
}

.correct {
  background-color: #52891b;
}

.incorrect {
  background-color: #930404;
}

.review-wrapper h1 {
  text-align: center;
}

.review-wrapper p { 
  height: 100%;
  text-align: left;
}

.review-text-wrapper {
  /* display: flex; */
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  max-height: 23vh;
  padding: 0 30px 00 30px;
  overflow-y: auto !important;
  font-family: 'Barlow Regular';
  font-size: 16pt;
  text-align: center;
}

.review-text-wrapper b {
  color: #faad43;
}

.review-text-wrapper .answer, .review-text-wrapper .subject, .review-text-wrapper .response {
  font-size: 13pt;
}

.date-answer, .date-response {
  font-size: 15pt;
}

/* .img-wrapper {
  max-height: 200px;
  object-fit: contain;
} */

.review-wrapper img {
  margin: 10px 0 10px 0;
  min-height: initial;
  max-height: 18vh !important;
  object-fit: contain !important;
}

.review-wrapper ion-button {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 90%;
  align-self: center;
  min-height: unset;
}

.scroll-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* creates and animates a cheveron to indicate text is scrollable*/
.scroll-indicator {
  position: relative;
  top: -0px;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
} 

.scroll-indicator {
  animation: rotate_pulse 7s .1s 1 ease-out forwards;
  opacity: 0;
}

@keyframes rotate_pulse {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  10% {
    opacity: .1;
    transform: rotate(-45deg);
  }
  33% {
    opacity: .6;
    transform: rotate(-45deg);
  }
  66% {
    opacity: .1;
    transform: rotate(-45deg);
  }
  100% {
    opacity: .6;
    transform: rotate(-45deg);
  }
}

@media screen and (min-height: 700px) {
  .review-wrapper .header {
    height: 35px;
  }

  .correct, .incorrect {
    max-height: 55px;
  }

  .review-wrapper img {
    max-height: 22vh !important;
  }

}

@media screen and (min-height: 1000px) and (min-width: 700px) {
  .review-wrapper .header {
    height: 50px;
  }

  .correct, .incorrect {
    max-height: 75px;
  }

  .review-wrapper {
    padding-bottom: 10px;
  }

  .review-wrapper ion-button {
    width: 70%;
    height: 100px;
    font-size: 36pt
  }

}

@media screen and (min-height: 1300px) and (min-width: 1000px) {
  .review-wrapper {
    padding-bottom: 50px;
  }
}