.ios #internet-warning .modal-wrapper {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
}
#internet-warning .modal-wrapper {
    justify-content: space-between;
}
#internet-warning .message-block {
    color: white;
    font-size: 20pt;
    align-self: center;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
#internet-warning .warning-message {
    margin-bottom: 20px;
}

#internet-warning ion-button {
    font-size: 22pt;
    height: 50px;
    width: 75%;
    margin-bottom: 20px;
    align-self: center;
    min-height: unset;
}

#internet-warning ion-button.yes {
    box-shadow: 5px 10px 5px rgba(0, 0, 0, .5) !important;
}

#internet-warning ion-button.no {
    --background: transparent;
    --border-color: #faad43;
    --border-style: solid;
    --border-width: 2px;
}

#internet-warning ion-button.yes.ion-activated {
    --border-color: #a86b18 !important;
}
#internet-warning .message-block {
    height: 60%;
}

#internet-warning .warning-message {
    font-size: 20pt;
}

@media screen and (min-height: 1000px) and (min-width: 768px) {
    #internet-warning .message-block {
        margin-top: 6%;
    }
}