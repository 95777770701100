/* temp debug styles */

.table-wrapper {
    color: white;
}

.scores-wrapper {
    display: flex;
    flex: 1 1 auto;
    max-height: 100%;
    flex-direction: column;
    justify-content: flex-start;
}

.scores-wrapper .scope-selector {
    padding: 0 5px 0 5px;
}

.scores-wrapper .scope-selector ion-segment-button {
    font-size: 22pt;
    height: 2em;
    border-color: #a86b18;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0 5px 5px 0;
}

.scores-wrapper .scope-selector ion-segment-button:last-child {
    margin-right: 0;
}

.scores-wrapper .scope-selector ion-segment-button.segment-button-checked {
    border-color: #faad43;
}

.scores-wrapper .scope-ui {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    border: solid 2px #a86b18;
    margin: 0 5px 5px 5px;
    overflow-y: scroll;
}

.scores-wrapper .instruction-label, .scores-wrapper .scores-explanation-label {
    color: white;
    align-self: flex-start;
    margin: 5px 0 3px 10px;
}

.explanation-text {
    font-size: 18px;
    color: white;
}

.cat-row {
    align-self: flex-start;
    margin-top: 5px;
}

.explanation-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 3px 0 3px 0;
}

#cat-icon {
    margin-left: 10px;
    width: 1.2em;
    height: 1.2em;
}

#cat-label {
    margin-left: 10px;
    color: white;
}

.scores-wrapper .category-selector {
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
}

.scores-wrapper .category-selector ion-segment-button {
    border: none;
    min-height: 0;
    min-width: 0;
    --background-checked: none;
    --ripple-color: transparent;
    --padding-start: 10px;
    --padding-end:10px;
    --padding-top:10px;
}

.scores-wrapper .category-selector ion-icon {
    margin: 0;
}

.scores-wrapper .category-selector ion-label {
    font-size: 10pt;
    text-transform: initial;
    color: #faad43;
    margin: 0;
}

.scores-wrapper .category-selector .segment-button-checked ion-label {
    color: #a86b18;
}

.category-selector path {
    fill: #faad43;
}

.scores-wrapper .date-range-selector {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
}

.scores-wrapper .date-range-selector ion-segment-button.segment-button-checked {
    border-color: #faad43;
}

.scores-wrapper .date-range-selector ion-segment-button {
    font-size: 12pt;
    min-height: 18pt;
    max-height: none;
    min-width: 28vw;
    border-color: #a86b18;
    height: fit-content;
    margin: 0 5px 0 0;
}

.scores-wrapper .date-range-selector ion-segment-button:last-child {
    margin-right: 5px;
}

.scores-wrapper .date-range-selector ion-label {
    margin: 0;
}

.scores-wrapper h1 {
    color: #faad43;
    font-size: 32pt;
}

.scores-wrapper ion-select {
    color: white;
    font-size: 24pt;
}

.scores-wrapper .table-wrapper {
  font-size: 9pt;
  max-height: 150px;
  min-height: 80px;
  padding: 0;
  width: 95%;
  align-self: center;
}

.scores-wrapper table {
    width: 100%;
}

.scores-wrapper table tr {
  color: white;
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}

.scores-wrapper tr.headers {
  font-family: 'BarlowSemiCondensed Regular';
  color: #d89130;
  border-bottom: solid 1px #d89130;
  padding-bottom: 5px;
}

.scores-wrapper th.column-selected {
    color: #a86b18;
}

.scores-wrapper th.column-desc:after, .scores-closed:after {
    content: "\25BE";
}

.scores-wrapper th.column-asc:after, .scores-open:after {
    content: "\25BE";
    display: inline-block;
    transform: rotate(180deg);
}

.scores-wrapper th.column-desc:after, .explanation-closed:after {
    content: "\25BE";
}

.scores-wrapper th.column-asc:after, .explanation-open:after {
    content: "\25BE";
    display: inline-block;
    transform: rotate(180deg);
}

.scores-wrapper tr.yours {
    color: #a86b18;
    font-family: 'Barlow Black';
}

.scores-wrapper tr.latest {
    font-family: 'Barlow Black'; 
}

.scores-wrapper table td, .scores-wrapper table th {
  width: 30%;
  text-transform: uppercase;
  text-align: center;
}

.scores-wrapper .leaderboard-button {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    align-self: center;
}

.scores-wrapper .no-scores td {
    width: 100% !important;
    text-transform: none;
    font-size: 22pt;
}

.scores-wrapper .scope-ui {
    max-height: 68%;
}

.scores-wrapper {
    max-height: 100%;
}

@media screen and (min-height: 600px) {
    .scores-wrapper .scope-selector ion-segment-button {
        height: 2.5em;
    }

    .scores-wrapper .table-wrapper {
        font-size: 13pt;
    }
    .scores-wrapper .category-selector ion-segment-button {
        --padding-start: 16px;
        --padding-end:16px;
    }
    .scores-wrapper .scope-ui {
        max-height: 100%;
    }
    
}

@media screen and (min-height: 700px) {
    .scores-wrapper ul {
        max-height: 50vh;
    }

}

@media screen and (min-height: 1000px) and (min-width: 700px) {
    .scores-wrapper .scope-selector ion-segment-button {
        height: 3.5em;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    
    .scores-wrapper .category-selector ion-icon {
        width: 50px;
        height: 50px;
    }
    .scores-wrapper .category-selector ion-segment-button {
        height: 8em;
        width: 12em;
        --padding-start: 13px;
        --padding-end:13px;
    }
    .scores-wrapper .scope-ui {
        border: none;
        border-top: solid 3px #a86b18;
        margin: 0;
    }

    .scores-wrapper .table-wrapper {
        font-size: 24pt;
    }

    #cat-icon {
        width: 2em;
        height: 2em;
    }

    #cat-label {
        margin-left: 21px;
        font-size: 25px;
    }

    .scores-wrapper .date-range-selector ion-segment-button {
        font-size: 19pt;
        min-height: 33pt;
    }

    .explanation-text {
        font-size: 20pt;
    }
}

@media screen and (min-height: 1300px) and (min-width: 1000px) {
    .scores-wrapper ul {
        max-height: 60%;
    }
}

/* Use width queries for scoreboard headings */
@media screen and (max-width: 420px) {
    .scores-wrapper .table-wrapper {
        font-size: 12pt;
    }
}
@media screen and (max-width: 390px) {
    .scores-wrapper .table-wrapper {
        font-size: 11pt;
    }
}
@media screen and (max-width: 360px) {
    .scores-wrapper .table-wrapper {
        font-size: 10pt;
    }
}
@media screen and (max-width: 327px) {
    .scores-wrapper .table-wrapper {
        font-size: 8pt;
    }
}

.column-selected, .score-record.headers {
    cursor: default;
}