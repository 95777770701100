.bottom-bar {
    height: 40px;
    background: rgba(0,0,0,0.4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ios .bottom-bar {
    padding-bottom: 15px;
}

.bottom-bar ion-icon {
    height: 90%;
    width: 20px;
    margin: 2px 5px 0 5px;
    padding: 0 1.5em 0 1.5em;
}

.bottom-bar ion-icon.active {
    color: #faad43 !important;
}

@media screen and (min-height: 700px) {
    .bottom-bar {
        height: 50px;
    }

    .bottom-bar ion-icon {
        margin-top: 2px;
        width: 26px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 700px) {
    .bottom-bar {
        height: 60px;
    }

    .bottom-bar ion-icon {
        width: 29px;
        margin: 5px;
    }
}

@media screen and (min-height: 1300px) and (min-width: 1000px){
    .bottom-bar {
        height: 75px;
    }

    .bottom-bar ion-icon {
        width: 37px;
        margin: 4px;
    }
}