.splash-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-content: center;
    align-self: center;
}

.static-logo-container, .logo-tagline-container {
    align-self: center;
    margin: 18px 0 20px 0 !important;
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.ios .static-logo-container, .ios .logo-tagline-container {
    margin-top: 30px !important;
}

.logo-tagline-container {
    position: relative;
    transform: translateY(30vh);
}

.logo-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: center;
    height: 40pt;
    width: 100%;
    /* margin-bottom: 5px; */
}

.word-origins-base {
    margin: none;
    height: 100%;
    width: 100%;
}

/* refers to tagline in moving splash logo */
.logo-tagline-container > ion-icon {
    margin: 0;
    width: 67% !important;
    height: 15pt !important;
    opacity: 0;
    align-self: center;
}


.static-tagline-svg {
    margin: 0;
    width: 67%;
    height: 15pt;
    align-self: center;
}

@media screen and (min-height: 600px) {
    .logo-container {
        height: 75px;
    }

    .ios .static-logo-container, .ios .logo-tagline-container {
        height: 100px;
    }
}

@media screen and (min-height: 700px) {
    .static-logo-container, .logo-tagline-container {
      margin: 50px 0 20px 0 !important;
    }
    

    .ios .static-logo-container, .ios .logo-tagline-container {
        margin-top: 30px !important;
        height: 150px;
    }
  }

@media screen and (min-height: 1300px) and (min-width: 1000px) {
    .static-logo-container, .logo-tagline-container {
        margin-top: 150px !important;
    }

    .ios .static-logo-container, .ios .logo-tagline-container {
        margin-top: 200px !important;
    }

    .logo-container {
        height: 75px;
    }

    .logo-tagline-container > ion-icon, .static-tagline-svg {
        height: 25px !important;
    }
}