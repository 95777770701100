.question-wrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex: inherit;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}

.question-header {
  min-height: 29%;
  max-height: 29%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(20, 20, 20, 0.4);
  text-align: center;
}

.question-header p {
  color: white;
  display: inline-block;
  width: 80%;
  max-height: 54%;
  overflow-y: scroll;
  font-size: 14pt;
  font-weight: 600;
  font-family: 'BarlowSemiCondensed Regular';
  text-align: center;
}

.question-wrapper .question-header.med p {
  font-size: 14pt;
}

.question-wrapper .question-header.long p {
  font-size: 12pt;
}

.question-wrapper .question-header.extra-long p {
  font-size: 10pt;
}


.question-wrapper .segment-button-checked {
  --border-color: #faad43;
}

.question-wrapper ion-segment, .question-wrapper ion-reorder-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px 0 10px 0;
}

.question-wrapper ion-segment-button, ion-reorder {
  --background-checked: #faad43;

  max-width: none;
  width: 90%;
  align-self: center;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

.question-wrapper ion-segment-button ion-label {
  font-size: 16pt;
  overflow: visible;
}

.question-wrapper ion-segment-button {
  --ripple-color: transparent;
}

.question-wrapper .qreview-button {
  opacity: 1;
}

.question-wrapper .qreview-button.segment-button-checked {
  --border-color: #51891a;
  --background-checked: #51891a;
}

#qReview .question-wrapper ion-segment {
  margin-top: -9vh;
}

#qReview .question-wrapper ion-segment-button {
  margin-bottom: 11px;
}

ion-item {
  --background: transparent;

  height: 100%;
  justify-content: center;
  text-align: center;
}

.item-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  text-align: justify;
}

.item-wrapper ion-icon {
  height: 100%;
  width: 15px;
  line-height: 100%;
  justify-self: left;
}

.question-wrapper ion-label {
  align-self: center;
  width: 100%;
  font-family: 'BarlowSemiCondensed Regular';
  font-weight: 600;
  font-size: 18pt;
  text-align: center;
}

.question-wrapper ion-label.med {
  font-size: 14pt;
}

.question-wrapper ion-label.long {
  font-size: 12pt;
}

.question-wrapper ion-label.extra-long {
  font-size: 10pt;
}

.item-wrapper ion-label {
  font-size: 20pt !important;
 
  display: inline-block !important;
  width: 100% !important;
  line-height: 100%;
  position: relative;
  align-self: center;
}


.md .item-wrapper ion-label {
  top: -25%;
  right: -8%;
}

.question-wrapper ion-button {
  margin: 0 0 20px 0 !important;
  width: 90%;
  align-self: center;
  --background: #a86b18;
  min-height: unset;
}

.question-wrapper ion-button[disabled="false"] {
  --background: #faad43;
}

.date-button-text{
  text-transform: none;
}

.question-wrapper.hard ion-segment-button {
  height: 40px;
}

.question-wrapper.hard ion-segment {
  margin: 5px 0 5px 0;
}

.tobbar-wrapper ~ .ad-buffer {
  display: none;
}

@media screen and (min-height: 600px) {
  .question-header {
    min-height: 25%;
  }

  .question-wrapper ion-segment-button ion-label {
    font-size: 18pt;
  }

  .item-wrapper ion-label {
    font-size: 24pt !important;
  }

}

@media screen and (min-height: 700px) {
  .question-header {
    min-height: 36%;
    max-height: 36%;
  }

  .question-header h1 {
    margin: 20px 0 20px 0;
    font-size: 32pt;
  }

  .question-header p {
    font-size: 19pt;
  }

  .question-wrapper .question-header.med p {
    font-size: 18pt;
  }
  
  .question-wrapper .question-header.long p {
    font-size: 17pt;
  }
  
  .question-wrapper .question-header.extra-long p {
    font-size: 16pt;
  }

}

@media screen and (min-height: 900px), (min-width: 768px) {
  .question-wrapper ion-segment-button {
      width: 80%;
  }
}
@media screen and (min-height: 1000px) and (min-width: 768px) {
  
  .question-header {
    min-height: 29%;
    max-height: 29%;
  }
  .question-wrapper {
    padding-bottom: 10px;
  }

  .question-wrapper ion-button {
    width: 70%;
    height: 90px;
    font-size: 36pt;
  }
  
  .question-wrapper ion-reorder {
    margin-bottom: 10px;
    width: 60%;
    --border-width: 3px !important;
  }

  .question-wrapper ion-segment-button {
    display: flex;
    justify-content: center;
    min-height: 64px;
    width: 60%;
    border-width: 3px;
    margin-bottom: 10px;
  }
  
  .question-wrapper ion-segment-button ion-label.short {
    font-size: 28pt;
  }

  .question-wrapper ion-segment-button ion-label.med {
    font-size: 20pt;
  }

  .question-wrapper ion-segment-button ion-label.long {
    font-size: 16pt;
  }

  .question-wrapper ion-segment-button ion-label.extra-long {
    font-size: 13pt;
  }

  .question-wrapper ion-item .item-wrapper ion-label {
    font-size: 28pt !important;
    line-height: 63px;
    height: 63px;
  }
  
  .question-wrapper ion-label {
    width: 100%;
    min-width: 100%;
    font-size: 28pt;
  }

  .question-wrapper .question-header h1 {
    font-size: 50pt;
    margin: 30px 0 50px 0;
  }

  .question-wrapper .question-header p {
    font-size: 32pt;
  }

  .question-wrapper .question-header.med p {
    font-size: 26pt;
  }
  
  .question-wrapper .question-header.long p {
    font-size: 24pt;
  }
  
  .question-wrapper .question-header.extra-long p {
    font-size: 22pt;
  }


  .md .item-wrapper ion-label {
    top: -40%;
    right: -8%;
  }

  
  .item-wrapper ion-icon {
      min-width: 20px;
  }
}

@media screen and (min-height: 1300px)  and (min-width: 1000px) {
  .question-wrapper {
    padding-bottom: 50px;
  }

  .question-wrapper ion-segment-button {
    margin-bottom: 20px;
  }
  
  .question-wrapper ion-reorder {
    margin-bottom: 20px;
    --border-width: 3px !important;
  }


  .md .question-wrapper .item-wrapper ion-label {
    font-size: 36pt !important;
    top: -40%;
  }

  .question-wrapper .question-header p {
    font-size: 42pt;
  }

  .question-wrapper .question-header.med p {
    font-size: 38pt;
  }
  
  .question-wrapper .question-header.long p {
    font-size: 36pt;
  }
  
  .question-wrapper .question-header.extra-long p {
    font-size: 34pt;
  }

}

@media screen and (min-width: 1024px) {
  .question-header {
    min-height: 30%;
    max-height: 30%;
  }
}
