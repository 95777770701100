.summary-wrapper {
  height: 100%;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex: inherit;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.summary-header {
  color: white;
  background: rgba(20, 20, 20, 0.4);
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.summary-header h1 {
  margin: 0;
  font-size: 50pt;
  line-height: 50pt;
  font-family: 'Barlow Black';
}

.summary-header h2 {
  margin: 0;
  color: #faad43;
  font-size: 25pt;
}

.summary-header h3 {
  margin: 0;
  color: #faad43;
  font-weight: 600;
  font-family: 'Barlow Black';
}

.summary-header h3.short {
  font-size: 28pt;
}

.summary-header h3.med {
  font-size: 25pt;
}

.summary-header h3.long {
  font-size: 18pt;
}

.summary-wrapper h4 {
  font-size: 20pt;
  font-family: 'Barlow Black';
  margin: 0;
}

.summary-wrapper .summary-scores-wrapper {
  height: 40%;
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Barlow Regular';
}

.summary-wrapper .summary-scores-wrapper ion-input {
  max-width: 50%;
  align-self: center;
}

.summary-wrapper .cloned-input {
  display: none;
}

.summary-wrapper .summary-scores-wrapper input {
  text-transform: uppercase;
  font-size: 20pt;
  text-align: center;
  background:rgba(100, 100, 100, 0.4);
  border-radius: 5px;
  color: white;
  height: 40px;
  transform: none !important;
}

p, h1, h2, h3, h4, span {
  display: block;
  text-align: center;
}

.summary-wrapper table {
  font-size: 11pt;
  max-height: 130px;
  min-height: 80px;
  overflow: scroll;
  padding: 0;
  border-bottom: solid 1px #d89130;
  width: 100%;
  align-self: center;
}

.summary-wrapper table tr {
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}

.summary-wrapper tr.list-headers {
  font-family: 'BarlowSemiCondensed Regular';
  color: #d89130;
  border-bottom: solid 1px #d89130;
  padding-bottom: 5px;
}

.sc-ion-input-md-h {
  --padding-end: 8px;
}

.sc-ion-input-ios-h {
  --padding-start: 10px;
}

.summary-wrapper tr td:first-child, .summary-wrapper tr th:first-child,
.summary-wrapper tr td:last-child, .summary-wrapper tr th:last-child{
  width: 15%;
}

.summary-wrapper table td, .summary-wrapper table th {
  width: 35%;
  text-transform: uppercase;
  text-align: center;
}

.summary-wrapper tr.latest-record {
  font-family: 'Barlow Black';
}

.share-text p {
  color: white;
  display: inline-block;
  width: 80%;
  max-height: 60%;
  font-size: 13pt;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 600;
  font-family: 'BarlowSemiCondensed Regular';
  text-align: center;
}

.share-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.bottom-buttons, .score-submit-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.share-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.share-button ion-button {
  width: 87%;
  align-self: center;
  --background: #a86b18;
  min-height: unset;
  font-size: 17pt;
}

.summary-wrapper ion-button[disabled="false"] {
  --background: #faad43;
}

.score-submit-button {
  height: 48px;
  font-size: 14pt;
  min-height: unset;
}

.score-submit-button button {
  min-height: unset;
}

.score-submit-button .button-native {
  min-height: unset;
}

.bottom-buttons ion-button {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14pt;
  width: 40%;
  align-self: center;
  min-height: unset;
}

@media screen and (min-height: 600px) {
  .summary-header h2 {
    font-size: 30pt;
  }

  .summary-header h3.short {
    font-size: 30pt;
  }

  .summary-header h3.med {
    font-size: 26pt;
  }

  .summary-header h3.long {
    font-size: 19pt;
  }

  .summary-wrapper ul {
    max-height: 200px;
  }

  .summary-wrapper .summary-scores-wrapper input {
    font-size: 22pt;
    height: inherit;
  }

  .bottom-buttons ion-button {
    font-size: 17pt;
  }

  .summary-header h1 {
    margin: 0;
    font-size: 60pt;
    line-height: 60pt;
    font-family: 'Barlow Black';
  }
  .summary-wrapper table {
    max-height: 150px;
  }
  .share-text p {
    font-size: 15pt;
  }

  .share-button ion-button {
    font-size: 19pt;
  }
}

@media screen and (min-height: 700px) {
  .summary-wrapper table {
    max-height: 200px;
  }
  .share-text p {
    font-size: 17pt;
  }
  .summary-header h2 {
    font-size: 33pt;
  }

  .share-button ion-button {
    font-size: 20pt;
  }
}

@media screen and (min-height: 800px) {
  .summary-header h2 {
    font-size: 40pt;
  }

  .summary-header h3.short {
    font-size: 36pt;
  }

  .summary-header h3.med {
    font-size: 30pt;
  }
  
  .summary-wrapper table {
    max-height: 250px;
    width: 100%;
    align-self: center;
  }
  
  .summary-wrapper table tr {
    margin-bottom: 7px;
  }
  .share-text p {
    font-size: 18pt;
  }

  .share-button ion-button {
    font-size: 23pt;
  }
}

@media screen and (min-height: 1000px) and (min-width: 768px) {
  .summary-header > h1 {
    font-size: 85pt;
  }
  
  .summary-header > h2 {
    font-size: 54pt;
  }

  .summary-header h3.short {
    font-size: 50pt;
  }

  .summary-header h3.med {
    font-size: 38pt;
  }

  .summary-header h3.long {
    font-size: 28pt;
  }

  .summary-wrapper > h4 {
    font-size: 30pt;
  }

  .summary-wrapper > span {
    font-size: 26pt;
  }

  .summary-wrapper .summary-scores-wrapper h4 {
    font-size: 34pt;
  }

  .summary-wrapper .summary-scores-wrapper input {
    font-size: 32pt;
  }

  .summary-wrapper table {
    max-height: 500px;
    font-size: 16pt;
    width: 90%;
    border-width: 2px;
  }

  .summary-wrapper tr.list-headers {
    border-width: 2px;
  }
  
  .summary-wrapper table tr {
    margin-bottom: 10px;
  }

  .bottom-buttons ion-button {
    font-size: 34pt;
  }
  .share-text p {
    font-size: 22pt;
  }

  .score-submit-button {
    height: 64px;
    font-size: 18pt;
  }

  .share-button ion-button {
    font-size: 29pt;
  }
}