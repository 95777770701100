.top-bar {
    height: fit-content;
    background: rgba(0,0,0,0.4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 3px 0 3px 0;
}

.top-bar.hide-top-bar {
    display: none;
}

.ios .ios-buffer {
    padding: constant(safe-area-inset-top) 0 5px 0 !important;
    padding: env(safe-area-inset-top) 0 5px 0 !important;
}

.ios .ad-buffer {
    margin: constant(safe-area-inset-top) 0 5px 0 !important;
    margin: env(safe-area-inset-top) 0 5px 0 !important;
}

.top-bar .logo {
    margin-top: 10px;
    width: 150px;
    height: 35px;
    align-self: center;
}

.top-bar>ion-icon {
    margin: 5px 10px 5px 10px;
}

.top-bar .icon-wrapper {
    height: 35px;
    display: flex;
    width: 18%;
    align-self: center;
}

.top-bar .icon-wrapper-right {
    height: 45px;
    display: flex;
    width: 18%;
    align-self: center;
    flex-direction: row-reverse;
}

.top-bar .ui-icon{
    height: 35px;
    width: 35px;
    align-self: center;
    margin: 0px 0px 0px 10%;
}

.top-bar .close-icon {
    height: 30px;
    width: 30px;
    align-self: center;
    margin: 0px 15% 0px 0px;
}

.top-bar .question-index {
    min-width: 18%;
    max-width: 18%;
    display: flex;
    justify-content: flex-end;
}

.top-bar .question-index h1 {
    color: #faad43;
    font-size: 20pt;
    margin: 5px 10% 0px 5px;
}

@media screen and (min-height: 700px) {
    .ios .top-bar .logo, .ios .top-bar .ui-icon, .ios .top-bar .ui-icon .close-icon {
        margin-bottom: 10px;
    }

    .top-bar .ui-icon {
        height: 40px;
        width: 40px;
    }

    .top-bar .close-icon {
        height: 34px;
        width: 34px;
    }

    .top-bar .question-index h1 {
        font-size: 24pt;
    }
}

@media screen and (min-height: 1000px) and (min-width: 700px) {

    .top-bar .category-icon {
        border-width: 2px;
    }

    .top-bar .logo {
        width: 230px;
        height: 65px;
    }

    .top-bar .ui-icon {
        height: 55px;
        width: 55px;
    }

    .top-bar .close-icon {
        height: 65px;
        width: 47px;
    }

    .top-bar .icon-wrapper-right {
        height: 85px;
    }

    .top-bar .question-index h1 {
        font-size: 32pt;
    }

}

@media screen and (min-height: 1300px) and (min-width: 1000px) {

    .top-bar>ion-icon {
        margin: 10px 15px 10px 15px;
    }

    .top-bar .logo {
        height: 80px;
    }

    .top-bar .ui-icon {
        height: 65px;
        width: 65px;
    }

    .top-bar .close-icon {
        height: 80px;
        width: 55px;
    }

    .top-bar .question-index h1 {
        font-size: 50pt;
    }
}