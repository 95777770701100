.loading {
  width: 100%;
  height: 100%;
  display: flex; 
  flex-direction: column;
  justify-content: space-around;
  font-size: 36pt;
}

.loading ion-img {
  width: 70%;
  align-self: center;
  padding-left: 20px;
}

.splash-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-around;
}

.ui-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.ad-buffer {
  height: 50px;
}

div.invisible {
  opacity: 0;
  pointer-events: none!important;
}

div.invisible .start-button {
  pointer-events: none;
}

div.visible {
  opacity: 1;
  pointer-events: auto;
}

SetupUI {
  position: fixed;
}

@media screen and (min-height: 1000px) and (min-width: 700px) {
  .ui-wrapper {
    max-height: 90%;
    max-width: 90%;
    align-self: center;
  }

  .loading ion-img {
    width: 30%;
    align-self: center;
    padding-left: 20px;
  }
}

@media screen and (min-height: 1300px) and (min-width: 1000px) {
  .ui-wrapper {
    margin-top: 2%;
    max-height: 75%;
    max-width: 75%;
    align-self: center;
  }
}